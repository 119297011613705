import "./App.css";
import About from "./components/About";
import Services from "./components/Services/Services";
import Contact from "./components/Contact";
import MainPage from "./components/MainPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./components/RootLayout";
import Terms from "./components/Terms";
import Careers from "./components/Careers";
import CandidateReg from "./components/CandidateReg";
import Error404 from "./components/Error404";

import Login from "./components/DataFormsColl/Login";

import { useEffect, useState } from "react";
import Modal from "./components/Modal";
import UnderConstruction from "./components/UnderConstruction";
import Clients from "./components/Clients/ClientsView";

// Menu Route list
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/", element: <MainPage /> },
      { path: "/About", element: <About /> },
      { path: "/Services", element: <Services /> },
      { path: "/Clients", element: <Clients /> },
      { path: "/Contact", element: <Contact /> },
      { path: "/Careers", element: <Careers /> },
      { path: "/Terms", element: <Terms /> },
      { path: "/CandidateReg", element: <CandidateReg /> },
      { path: "/UD", element: <UnderConstruction /> },
      {
        path: "*",
        element: (
          <div style={{ height: "650px" }}>
            <Error404 />
          </div>
        ),
      },

      // DataForms Routes
      { path: "/FormsLogin", element: <Login /> },
    ],
  },
]);

function App() {
  // Modal State
  const [showModal, setShowModal] = useState(false);
  const currentDate = () => {
    const date = new Date();
    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    if (currentDate() === "12-10-2024" || currentDate() === "13-10-2024") {
      setShowModal(true);
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {/* Modal Function Calling */}
      {showModal && <Modal onClose={closeModal} />}
      <div>
        <RouterProvider router={router} />
      </div>
    </div>
  );
}
export default App;
