import { Link } from "react-router-dom";
import ServicesListData from "./ClientsListData";
import {
  Typography,
  Grid,
  Skeleton,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
} from "@mui/material";

// files

const Clients = () => {
  return (
    <div>
      <section id="clients" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Our Clients
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  border: "1px solid black",
                  borderStyle: "dashed",
                  width: "10%",
                }}
              />
            </Box>
          </div>
          <div className="row">
            {ServicesListData.map((service, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 icon-box"
                data-aos="fade-up"
                data-aos-delay={service.time}
              >
                <Card
                  sx={{
                    // maxWidth: { lg: "90%", md: "100%" },
                    margin: "auto",
                    borderRadius: "20px",
                    backgroundColor: "#FBF8F1",
                    my: 1,

                    transition: "box-shadow 0.3s",
                    "&:hover": {
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.6)",
                    },
                    width: { lg: "auto" },
                  }}
                  raised
                >
                  <CardContent>
                    {service.title === "Quick MD Care" ||
                    service.title === "PrimeS4" ||
                    service.title === "PrideMedia" ? (
                      <div>
                        <img
                          alt={service.title}
                          src={service.icon}
                          style={{
                            width:
                              service.title === "Texas Foster Care"
                                ? "8rem"
                                : "19rem",
                            height: "8rem",
                            aspectRatio: "4/3",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    ) : (
                      <Link to={service.link} target="_blank">
                        <img
                          alt={service.title}
                          src={service.icon}
                          style={{
                            width:
                              service.title === "Texas Foster Care" ||
                              service.title === "Sri Gayathri Exports" ||
                              service.title === "Small Business IT Solutions"
                                ? "8rem"
                                : "19rem",
                            height: "8rem",
                            borderRadius:
                              service.title === "Sri Gayathri Exports"
                                ? "50%"
                                : "", 
                            aspectRatio: "4/3",
                            objectFit: "contain",
                          }}
                        />
                      </Link>
                    )}
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Clients;
