import SriKamakshiCare from "./ClientsLogos/SriKamakshiCare.png";
import elton from "./ClientsLogos/eltonreneau.png";
import southpodiatry from "./ClientsLogos/southpodiatry.webp";
import texasFoster from "./ClientsLogos/TexasFosterCare.png";
import quickmdcare from "./ClientsLogos/quickmdcare.png";
import primes4 from "./ClientsLogos/prime4.png";
import prideMedia from "./ClientsLogos/pride.png";
import sirgayathri from "./ClientsLogos/SriGayathrinew.png";
import sbizitsolutions from "./ClientsLogos/sbizitsolutions.png"
import chiralarealestate from "./ClientsLogos/chiralarealestate.png"
import uptowncollar from "./ClientsLogos/uptowncollar.png";

const ClientsListData = [
  {
    icon: elton,
    title: "Elton M. Renuau & Co.,PC",
    link: "https://reneaucpa.com/",
  },
  {
    icon: SriKamakshiCare,
    title: "SriKamakshicare",
    link: "https://srikamakshicarehospital.com/",
  },

  {
    icon: southpodiatry,
    title: "Southtxpodiatry",
    link: "https://www.southtxpodiatry.com/",
  },

  {
    icon: quickmdcare,
    title: "Quick MD Care",
    link: "#",
  },
  {
    icon: primes4,
    title: "PrimeS4",
    link: "#",
  },
  {
    icon: prideMedia,
    title: "PrideMedia",
    link: "#",
  },
  {
    icon: texasFoster,
    title: "Texas Foster Care",
    link: "https://www.texasfostercare.org/",
  },
  {
    icon: sirgayathri,
    title: "Sri Gayathri Exports",
    link: "https://srigayathriexports.com/",
  },
  {
    icon: sbizitsolutions,
    title: "Small Business IT Solutions",
    link: "https://sbizitsolutions.com/",
  },
  {
    icon: chiralarealestate,
    title: "Chirala Real Estate ",
    link: "https://www.chiralarealestate.com/",
  },
  {
    icon: uptowncollar,
    title: "Uptown Collar",
    link: "https://www.uptowncollar.com/",
  },
];
export default ClientsListData;

